import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ServicePage from "./pages/ServicePage";
import TermsAndConditionPage from "./pages/TermsAndConditionPage";


function App() {
  return (
      <Router>
          <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/le-cabinet" element={<AboutPage />} />
              <Route path="/matieres/:serviceId" element={<ServicePage />} />
              <Route path="/politique-confidentialite" element={<TermsAndConditionPage />} />
          </Routes>
      </Router>
  );
}

export default App;
