import "../styles/components/HeroBanner.css";

const HeroBanner = ({title, text, image}) => {
    return <div className="hero" style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center"
    }}>
        <h1>{title}</h1>
        <p>{text}</p>
        <button onClick={() => window.location.href = 'tel:+3271328118'}>Appelez au 071 32 81 18</button>
    </div>;
}

export default HeroBanner;