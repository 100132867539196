import {Component} from "react";
import "../styles/components/Footer.css";

class Footer extends Component {
    render() {
        return <footer className="footer">
            <div className="footer-content">
                {/* Left Section */}
                <div className="footer-left">
                    <div className="logo">Avocat Ranieri</div>
                    <p>Avec plus de 30 ans d’expérience au Barreau de Charleroi, Santa Ranieri met son expertise et son
                        approche humaine au service de vos intérêts juridiques.</p>
                </div>

                {/* Center Section */}
                <div className="footer-center">
                    <div className="contact-item">
                        <i className="material-icons">location_on</i>
                        <p>Bd Alfred de Fontaine 21/4, 6000 Charleroi</p>
                    </div>
                    <div className="contact-item">
                        <i className="material-icons">phone</i>
                        <p>071 32 81 18</p>
                    </div>
                    <div className="contact-item">
                        <i className="material-icons">email</i>
                        <p>ranieri.avocate@skynet.be</p>
                    </div>
                </div>

                {/* Right Section */}
                <div className="footer-right">
                    <ul>
                        <li><a href="/">Accueil</a></li>
                        <li><a href="/le-cabinet">Cabinet d'avocats</a></li>
                        <li><a href="/politique-confidentialite">Politique et confidentialité</a></li>
                        <button onClick={() => window.location.href = 'tel:+3271328118'} className="cta-button">Prenez rendez-vous</button>
                    </ul>
                </div>
            </div>

            {/* Disclaimer */}
            <div className="footer-disclaimer">
                <p>&copy; {new Date().getFullYear()} Ranieri Avocate - Tous droits réservés.
                    Le contenu de ce site est fourni à titre purement informatif et ne constitue pas un conseil juridique.
                    Pour des conseils adaptés à votre situation, veuillez contacter un avocat qualifié.</p>
            </div>
        </footer>;
    }
}

export default Footer