import "../styles/components/Services.css";

const Service = ({id, title, description, icon}) => {
    return(
        <div className="service">
            <img src={icon} alt={`${title} icon`}/>
            <h3>{title}</h3>
            <p>{description}</p>
            <a className="read-more" href={`/matieres/${id}`}>Lire plus ></a>
        </div>
    )
}

export default Service;