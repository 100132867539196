import '../styles/HomePage.css';
import Header from "../components/Header";
import HeroBanner from "../components/HeroBanner";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import {servicesData} from "../assets/data";
import Service from "../components/Service";
import Presentation from "../components/Presentation";


function HomePage() {
    return (
        <div className="App">
            {/* Header */}
            <Header/>

            {/* Gradient line above the banner */}
            <div className="gradient-line"></div>

            {/* Hero section */}
            <HeroBanner
                title="Cabinet d'avocats expérimentés à Charleroi"
                text="Notre préoccupation c'est de nous occuper des vôtres."
                image={require("../assets/images/banners/cabinet-avocat-charleroi.jpg")}
            />

            {/* Gradient line below the banner */}
            <div className="gradient-line"></div>

            {/* Services section */}
            <div className="services">
                <h2>Nos compétences</h2>
                <div className="service-grid">
                    {servicesData.map((service, index) => (
                        <Service
                            key={index}
                            id={service.id}
                            title={service.title}
                            description={service.smallDescription}
                            icon={service.icon}
                        />
                    ))}
                </div>
            </div>

            {/* Text */}
            <Presentation/>

            {/* Contact */}
            <Contact/>

            {/* Gradient line above the footer */}
            <div className="gradient-line"></div>

            {/* Footer */}
            <div className="wrapper">
                <Footer/>
            </div>
        </div>
    );
}

export default HomePage;
