import React from "react";
import { useParams } from "react-router-dom";
import { servicesData } from "../assets/data";
import "../styles/ServicePage.css";
import Header from "../components/Header";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import HeroBanner from "../components/HeroBanner";
import DOMPurify from "dompurify";

const ServicePage = () => {
    const { serviceId } = useParams(); // Get the service ID from the URL
    const service = servicesData.find((item) => item.id === serviceId);

    if (!service) {
        return <div>Service not found.</div>;
    }

    // Split description text by `\n` and create an array of paragraphs
    const paragraphs = service.description.split("\n");

    // Sanitize HTML content
    const createSanitizedHTML = (html) => {
        return { __html: DOMPurify.sanitize(html) };
    };

    return (
        <div className="App">
            {/* Header */}
            <Header/>

            {/* Gradient line above the banner */}
            <div className="gradient-line"></div>

            {/* Hero section */}
            <HeroBanner
                title={service.bannerText}
                image={require("../assets/images/banners/cabinet-avocat-charleroi.jpg")}
            />

            {/* Gradient line below the banner */}
            <div className="gradient-line"></div>


            {/* Service Content */}
            <div className="service-page">
                <h2>Nos compétences</h2>
                <div className="service-content">
                    {/* Left Column: Use Cases */}
                    <div className="use-cases">
                        <h3>Cas Fréquents</h3>
                        <div className="use-cases-grid">
                            {service.useCases.map((caseItem, index) => (
                                <div key={index} className="use-case">
                                    <img src={caseItem.icon} alt={caseItem.text}/>
                                    <p>{caseItem.text}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Right Column: Description and Why Us */}
                    <div className="details">
                        <h3>Description</h3>
                        <div className="description">
                            {paragraphs.map((paragraph, index) => (
                                <p key={index} dangerouslySetInnerHTML={createSanitizedHTML(paragraph)}/>
                            ))}
                        </div>
                        {/*<p>{service.description}</p>*/}

                        <h3>Notre Approche</h3>
                        <ul>
                            {service.whyUs.map((point, index) => (
                                <li key={index}>{point}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            {/* Contact Us */}
            <Contact/>

            {/* Gradient line above the footer */}
            <div className="gradient-line"></div>

            {/* Footer */}
            <div className="wrapper">
                <Footer/>
            </div>
        </div>
    );
};

export default ServicePage;
