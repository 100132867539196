import React, {Component} from "react";
import {servicesData} from "../assets/data";
import "../styles/components/Header.css";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false,
        };
    }

    toggleMenu = () => {
        this.setState((prevState) => {
            const isMenuOpen = !prevState.isMenuOpen;

            // Lock or unlock body scrolling
            // document.body.style.overflow = isMenuOpen ? "hidden" : "auto";

            // Add or remove a class on the header for sticky adjustment
            const header = document.querySelector(".header");
            if (isMenuOpen) {
                header.classList.add("menu-open");
            } else {
                header.classList.remove("menu-open");
            }

            return {isMenuOpen: !prevState.isMenuOpen}
        });
    };

    isActivePage = (path) => {
        return window.location.pathname === path ? "active" : "";
    };

    isServicePage = () => {
        return window.location.pathname.startsWith("/matieres");
    };

    render() {
        const { isMenuOpen } = this.state;

        return  (
        <header className="header">
            <a href="/" className="logo">R</a>

            {/* Hamburger Menu */}
            <div className={`hamburger ${isMenuOpen ? "open" : ""}`} onClick={this.toggleMenu}>
                <div></div>
                <div></div>
                <div></div>
            </div>

            {/* Navigation Menu */}
            <nav>
                <ul className={isMenuOpen ? "show" : ""}>
                    <li className={`upperCase`}><a href="/" className={`${this.isActivePage("/")}`}>Accueil</a></li>
                    <li className={`dropdown upperCase`}>
                        <a className={`${this.isServicePage() ? "active" : ""}`}>Nos compétences <span className="dropdown-arrow">▼</span></a>
                        <ul className="dropdown-menu">
                            {servicesData.map((service, index) => (
                                <li><a href={`/matieres/${service.id}`} className={this.isActivePage(`/matieres/${service.id}`) ? "active" : ""}>{service.title}</a></li>
                            ))}
                        </ul>
                    </li>
                    <li className={`upperCase`}><a href="/le-cabinet" className={`${this.isActivePage("/le-cabinet")}`}>Le cabinet</a></li>
                    <li>
                        <a href="tel:+3271328118" className="cta-button">Prenez rendez-vous</a>
                    </li>
                </ul>
            </nav>
        </header>
        );
    }
}

export default Header