import {Component} from "react";
import "../styles/components/Presentation.css";

class Presentation extends Component {
    render() {
        return <div className="why-lawyer">
            <h2>Pourquoi faire appel à un avocat ?</h2>
            <div className="content">
                <div className="text">
                    <p>
                        Dans un monde où les règles juridiques régissent chaque aspect de notre vie — que ce soit la
                        famille, le
                        travail, la succession ou la protection des personnes — il est essentiel de prendre des décisions
                        éclairées.
                        À cet égard, l’avocat est votre allié indispensable pour naviguer dans la complexité des lois et des
                        procédures.
                    </p>
                    <p>
                        Le cabinet <strong>Ranieri</strong>, situé à Charleroi, vous accompagne avec expertise et humanité pour prévenir et
                        résoudre
                        vos litiges. Forte de plus de 30 ans d’expérience en droit civil, pénal et commercial, <strong>Santa
                        Ranieri</strong>,
                        avocate
                        au Barreau de Charleroi depuis 1991, incarne une approche pragmatique et personnalisée, toujours au
                        service de vos intérêts.
                    </p>
                    <ul>
                        <li><strong>Un conseil éclairé : </strong> Grâce à sa vision globale des litiges et sa maîtrise des
                            procédures,
                            votre avocate vous oriente pour prendre des décisions réfléchies et adaptées à votre situation.
                        </li>
                        <li><strong>Une expérience reconnue :</strong> De ses fonctions comme Juge de paix Suppléant à sa
                            maîtrise
                            des procédures en Cassation en matière pénale, Maitre Ranieri met son savoir-faire au service de
                            ses
                            clients pour garantir une défense optimale.
                        </li>
                        <li><strong>Une approche humaine :</strong> Au-delà de la technicité juridique, le cabinet s’engage
                            à
                            écouter,
                            comprendre, et répondre avec pragmatisme à vos attentes spécifiques.
                        </li>
                    </ul>
                    <p>
                        Que vous soyez confronté à une procédure judiciaire ou que vous souhaitiez anticiper les impacts de
                        vos
                        choix, <strong>Maitre Ranieri</strong> est là pour vous guider. Contactez votre <strong>avocate à Charleroi</strong> dès aujourd’hui
                        pour
                        bénéficier d’un accompagnement professionnel et sur mesure.
                    </p>
                </div>
                <div className="image">
                    <img src={require("../assets/images/avocate charleroi santa ranieri.jpg")} alt="Lawyer illustration"/>
                </div>
            </div>
        </div>;
    }
}

export default Presentation