import {Component} from "react";
import "../styles/components/Contact.css";

class Contact extends Component {
    render() {
        return <div className="contact-us">
            <h2>Comment nous contacter ?</h2>
            <div className="contact-page">
                <div className="contact-info">
                    <p><strong>Adresse :</strong> Bd Alfred de Fontaine 21/042, 6000 Charleroi</p>
                    <p><strong>Téléphone :</strong> 071 32 81 18</p>
                    <p><strong>Email :</strong> ranieri.avocate@skynet.be</p>
                    <button className="cta-button" onClick={() => window.location.href = 'tel:+3271328118'}>Prenez rendez-vous maintenant</button>
                </div>
                <div className="map">
                    <iframe
                        title="office-location"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2542.629123886776!2d4.446943299999999!3d50.4107491!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c2260c58c7e767%3A0x51845c52e718c6ef!2sRanieri%20Santa!5e0!3m2!1sfr!2sbe!4v1736628989571!5m2!1sfr!2sbe"
                        width="100%"
                        height="100%"
                        style={{border: 0}}
                        allowFullScreen=""
                        loading="lazy">
                    </iframe>
                </div>
            </div>
        </div>;
    }
}

export default Contact;