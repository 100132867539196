export const servicesData = [
    {
        id: "droit-de-la-circulation-routiere",
        title: "Roulage",
        bannerText: "Vous défendre en cas d'infraction, Vous protéger en cas d'accident",
        smallDescription: "Infractions routières, accidents ou suspension de permis : faites appel à notre expertise pour défendre vos droits sur la route.",
        description: "Le droit de la circulation routière concerne l’ensemble des infractions et litiges liés à l’utilisation de la route. Que vous soyez confronté à une infraction mineure, ou à un accident grave, notre cabinet met son expertise à votre disposition pour vous accompagner et défendre vos droits.\n" +
            "\n" +
            "Au cabinet Ranieri, à Charleroi, nous analysons votre dossier en profondeur pour vous conseiller sur les démarches les plus adaptées à votre situation. Grâce à une parfaite maîtrise des réglementations en vigueur et des procédures judiciaires, nous vous représentons avec rigueur devant les tribunaux et vous aidons à trouver des solutions efficaces. Nous mettons également tout en oeuvre pour l'optimisation de votre dommage matériel et corporel.",
        useCases: [
            { icon: require("./images/icons/Excès de vitesse.png"), text: "Excès de vitesse ou conduite sous influence : Assistance pour réduire les sanctions ou éviter une suspension de permis." },
            { icon: require("./images/icons/Accident de la route.png"), text: "Accidents de la route : Accompagnement pour établir les responsabilités, obtenir des réparations ou défendre vos intérêts en cas de poursuites." },
            { icon: require("./images/icons/Suspension du permis de conduire.png"), text: "Suspension ou retrait de permis : Conseils et représentation pour contester ou aménager une sanction." },
            { icon: require("./images/icons/Assurance auto.png"), text: "Assurances et indemnisation : Négociations avec les compagnies d’assurances pour garantir une indemnisation juste après un sinistre." },
            { icon: require("./images/icons/Infractions.png"), text: "Infractions diverses : Défense pour stationnement abusif, refus de priorité, ou dépassement dangereux." },
        ],
        whyUs: [
            "Écoute personnalisée : Nous prenons le temps de comprendre votre situation unique pour vous offrir des solutions adaptées",
            "Recherche d’accords amiables : Nous privilégions des solutions pacifiées et rapides lorsque cela est possible, afin de préserver vos relations et de limiter les conflits.",
            "Défense ferme : En cas de litige, nous vous représentons avec rigueur devant les tribunaux pour protéger vos droits.",
            "Expertise stratégique : Nous élaborons des stratégies juridiques efficaces, adaptées à vos besoins et objectifs.",
            "Accompagnement complet : De la première consultation jusqu’à la résolution du dossier, nous vous guidons à chaque étape du processus."
        ],
        icon: require("./images/banners/Droit de la circulation routière.png"),
    },
    {
        id: "droit-de-la-famille",
        title: "Famille et jeunesse",
        bannerText: "Le droit familial – Une expertise au service de vos besoins personnels",
        smallDescription: "Que ce soit pour des questions de divorce, de garde d'enfants ou de succession, le cabinet Ranieri vous accompagne avec compassion et expertise pour défendre vos intérêts familiaux.",
        description: "Le droit familial traite des relations entre les membres d’une famille, qu’il s’agisse de divorces, de la garde des enfants, ou de successions. Ces moments de vie, souvent délicats, nécessitent un accompagnement juridique sensible et rigoureux. Au cabinet Ranieri, à Charleroi , nous mettons à profit notre expertise et notre écoute pour vous guider dans vos démarches, tout en protégeant vos droits et ceux de vos proches",
        useCases: [
            { icon: require("./images/icons/Divorce.png"), text: "Divorce et séparation : Assistance dans les procédures amiables ou contentieuses." },
            { icon: require("./images/icons/Garde enfants.png"), text: "Garde des enfants : Définition des modalités d'hébergement, garde parentale, droit aux relations personnelles." },
            { icon: require("./images/icons/Pension alimentaire.png"), text: "Pension alimentaire : Calcul, négociation et ajustement des montants." },
            { icon: require("./images/icons/Partage des biens.png"), text: "Partage des biens : Liquidation du régime matrimonial et répartition équitable des biens communs." },
            { icon: require("./images/icons/Succession.png"), text: "Succession : Conseil et accompagnement dans les démarches liées à l’héritage et au partage successoral." },
        ],
        whyUs: [
            "Écoute personnalisée : Nous prenons le temps de comprendre votre situation unique pour vous offrir des solutions adaptées",
            "Recherche d’accords amiables : Nous privilégions des solutions pacifiées et rapides lorsque cela est possible, afin de préserver vos relations et de limiter les conflits.",
            "Défense ferme : En cas de litige, nous vous représentons avec rigueur devant les tribunaux pour protéger vos droits.",
            "Expertise stratégique : Nous élaborons des stratégies juridiques efficaces, adaptées à vos besoins et objectifs.",
            "Accompagnement complet : De la première consultation jusqu’à la résolution du dossier, nous vous guidons à chaque étape du processus.",
        ],
        icon: require("./images/banners/Droit de la famille.png"),
    },
    {
        id: "droit-des-assurances",
        title: "Assurances",
        bannerText: "Faites valoir vos droits en matière d’assurance",
        smallDescription: "Que vous soyez confrontés à un refus d'indemnisation ouu à un litige avec votre assureur, nous vous aidons à faire valoir vos droits efficacement.",
        description: "Le droit des assurances vise à protéger les assurés et à garantir que les engagements contractuels des compagnies d’assurance soient respectés. Que vous soyez confronté à un refus d’indemnisation, à une mauvaise évaluation des dommages, ou à un conflit lié à votre contrat, notre cabinet vous aide à obtenir réparation et justice.\n" +
            "\n" +
            "Au cabinet Ranieri, à Charleroi, nous mettons notre expertise juridique au service des particuliers et des entreprises pour analyser vos contrats d’assurance, négocier avec les compagnies et, si nécessaire, engager des actions judiciaires. Nous veillons à ce que vos droits soient respectés et que les indemnisations dues soient obtenues.",
        useCases: [
            { icon: require("./images/icons/Refus d'indemnisation.png"), text: "Refus d’indemnisation : Assistance pour contester une décision injustifiée de votre assureur." },
            { icon: require("./images/icons/Litige.png"), text: "Litiges après sinistre : Défense en cas de désaccord sur l’évaluation des dommages ou la couverture des garanties." },
            { icon: require("./images/icons/Assurance Habitation.png"), text: "Assurances habitation : Accompagnement dans les litiges liés aux dégâts des eaux, incendies, vols, ou autres sinistres domestiques." },
            { icon: require("./images/icons/Assurance auto.png"), text: "Assurances auto : Représentation pour les accidents de la route impliquant des désaccords sur les responsabilités ou les indemnisations." },
            { icon: require("./images/icons/Contrats.png"), text: "Contrats d’assurance : Analyse et vérification des clauses pour éviter les abus ou les mauvaises surprises." },
        ],
        whyUs: [
            "Écoute personnalisée : Nous prenons le temps de comprendre votre situation unique pour vous offrir des solutions adaptées",
            "Recherche d’accords amiables : Nous privilégions des solutions pacifiées et rapides lorsque cela est possible, afin de préserver vos relations et de limiter les conflits.",
            "Défense ferme : En cas de litige, nous vous représentons avec rigueur devant les tribunaux pour protéger vos droits.",
            "Expertise stratégique : Nous élaborons des stratégies juridiques efficaces, adaptées à vos besoins et objectifs.",
            "Accompagnement complet : De la première consultation jusqu’à la résolution du dossier, nous vous guidons à chaque étape du processus."
        ],
        icon: require("./images/banners/Droit des assurances.png"),
    },
    {
        id: "droit-des-contrats",
        title: "Contrat",
        bannerText: "Sécurisez vos engagements contractuels",
        smallDescription: "Rédaction, analyse ou litige contractuel: nous vous assistons pour garantir que vous engagements juridiques soient clairs et sécurisés.",
        description: "Le droit des contrats est au cœur des relations juridiques, qu’il s’agisse de l'achat ou la vente de biens ou marchandises, de prestations par corps de métiers, de vices ou malfaçons, ou de clauses abusives. Un contrat mal rédigé, mal interprété, ou mal exécuté peut entraîner des conséquences importantes, d’où la nécessité d’un accompagnement juridique expert.\n" +
            "\n" +
            "Au cabinet Ranieri, à Charleroi, nous vous assistons dans la rédaction, la révision et la négociation de vos contrats pour garantir leur conformité et leur sécurité juridique. En cas de litige, nous intervenons avec rigueur pour protéger vos intérêts et trouver une issue favorable, que ce soit à l’amiable ou devant les tribunaux.",
        useCases: [
            { icon: require("./images/icons/Rédaction de contrats.png"), text: "Rédaction de contrats : Création de documents juridiques clairs et adaptés à vos besoins spécifiques." },
            { icon: require("./images/icons/Analyse de contrats.png"), text: "Analyse et vérification : Étude approfondie de clauses contractuelles pour éviter tout déséquilibre ou abus." },
            { icon: require("./images/icons/Litige.png"), text: "Litiges contractuels : Représentation en cas de non-respect, d’interprétation erronée ou de rupture de contrat." },
            { icon: require("./images/icons/Négociations.png"), text: "Négociations : Assistance dans les discussions pour parvenir à un accord équitable entre les parties." },
            { icon: require("./images/icons/Vente.png"), text: "Contrats commerciaux : Vente, contrats d'entreprise, malfaçons ou défauts, prêts et reconnaissance de dettes." },
        ],
        whyUs: [
            "Écoute personnalisée : Nous prenons le temps de comprendre votre situation unique pour vous offrir des solutions adaptées",
            "Recherche d’accords amiables : Nous privilégions des solutions pacifiées et rapides lorsque cela est possible, afin de préserver vos relations et de limiter les conflits.",
            "Défense ferme : En cas de litige, nous vous représentons avec rigueur devant les tribunaux pour protéger vos droits.",
            "Expertise stratégique : Nous élaborons des stratégies juridiques efficaces, adaptées à vos besoins et objectifs.",
            "Accompagnement complet : De la première consultation jusqu’à la résolution du dossier, nous vous guidons à chaque étape du processus."
        ],
        icon: require("./images/banners/Droit des contrats.png"),
    },
    {
        id: "droit-bancaire",
        title: "Droit bancaire et saisies",
        bannerText: "Protégez vos droits face aux institutions bancaires",
        smallDescription: "Besoin d'assistance face à une instution bancaire ? Nous vous accompagnons danss les litiges relatifs aux crédits, prêts et autres relations bancaires.",
        description: "Le droit bancaire encadre les relations entre les institutions financières et leurs clients, qu’il s’agisse de particuliers ou d’entreprises. Les problématiques liées aux crédits, aux prêts ou aux saisies immobilières peuvent être complexes et nécessitent une expertise juridique approfondie pour garantir vos droits.\n" +
            "\n" +
            "Au cabinet Ranieri, à Charleroi, nous analysons minutieusement vos contrats et vos situations bancaires pour vous proposer des solutions adaptées. Qu’il s’agisse de négocier avec votre banque, de récupérer vos créances, ou de contester une saisie ou une procédure d'exécution, nous vous offrons un accompagnement complet et personnalisé.",
        useCases: [
            { icon: require("./images/icons/Crédits.png"), text: "Litiges liés à un crédit ou prêt : Assistance en cas de conditions contractuelles abusives ou de désaccords sur les remboursements." },
            { icon: require("./images/icons/Saisie.png"), text: "Saisies immobilières : Représentation devant le tribunal des saisies, ou auprès des notaires, pour obtenir paiement ou contester la saisie de vos biens." },
            { icon: require("./images/icons/Endettement.png"), text: "Surendettement : Conseils pour engager une procédure de médiation ou d’apurement des dettes." },
            { icon: require("./images/icons/Contrats.png"), text: "Clauses abusives : Analyse et contestation des conditions contractuelles contraires à vos droits." },
            { icon: require("./images/icons/Litige.png"), text: "Conflits bancaires : Accompagnement dans les désaccords liés aux comptes courants, placements financiers, ou investissements." },
        ],
        whyUs: [
            "Écoute personnalisée : Nous prenons le temps de comprendre votre situation unique pour vous offrir des solutions adaptées",
            "Recherche d’accords amiables : Nous privilégions des solutions pacifiées et rapides lorsque cela est possible, afin de préserver vos relations et de limiter les conflits.",
            "Défense ferme : En cas de litige, nous vous représentons avec rigueur devant les tribunaux pour protéger vos droits.",
            "Expertise stratégique : Nous élaborons des stratégies juridiques efficaces, adaptées à vos besoins et objectifs.",
            "Accompagnement complet : De la première consultation jusqu’à la résolution du dossier, nous vous guidons à chaque étape du processus."
        ],
        icon: require("./images/banners/Droit bancaire.png"),
    },
    {
        id: "droit-de-bail",
        title: "Bail",
        bannerText: "Propriétaire ou locataire, nous vous assistons dans vos litiges pour défendre vos droits avec efficacité.",
        smallDescription: "Propriétaire ou locataire, le cabinet Ranieri vous aide à gérer vos contrats de bail, résoudre vos différends et protéger vos droits en toute sérénité.",
        description: "Le droit du bail régit les relations entre propriétaires et locataires, qu’il s’agisse de locations résidentielles ou commerciales. Les litiges liés aux loyers, à l’entretien des biens, ou à la résiliation de contrats peuvent rapidement devenir complexes et nécessitent un accompagnement juridique pour garantir une résolution équitable.\n" +
            "\n" +
            "Les relations entre voisins peuvent parfois amener à des débats compliqués qui doivent être rapidement maitrisés pour garantir une résolution adéquate et paisible des conflits.\n" +
            "\n" +
            "Au cabinet Ranieri, à Charleroi, nous intervenons à chaque étape, de la rédaction ou la vérification du contrat à la gestion des différends. Que vous soyez locataire confronté à un problème d’habitation ou propriétaire souhaitant à récupérer des loyers impayés, nous vous aidons à défendre vos droits et à trouver des solutions adaptées à votre situation.",
        useCases: [
            { icon: require("./images/icons/Loyers impayés.png"), text: "Loyers impayés : Représentation des propriétaires pour recouvrer les loyers ou engager des procédures d’expulsion." },
            { icon: require("./images/icons/Litige.png"), text: "Litiges sur l’état des lieux : Assistance en cas de désaccord sur les dégradations ou l’état des biens loués." },
            { icon: require("./images/icons/Résiliation du bail.png"), text: "Résiliation du bail : Conseils et représentation pour mettre fin à un bail dans le respect des conditions légales." },
            { icon: require("./images/icons/Révision des loyers.png"), text: "Révision des loyers : Négociation ou contestation des augmentations de loyer abusives." },
            { icon: require("./images/icons/Réparations et maintenances.png"), text: "Obligations locatives : Accompagnement dans les litiges concernant l’entretien, les réparations, ou les charges locatives." },
            { icon: require("./images/icons/Conflits de voisinage.png"), text: "Conflits de voisinage : Servitude et droit de passage, troubles de voisinage, limites de propriétés." },
        ],
        whyUs: [
            "Écoute personnalisée : Nous prenons le temps de comprendre votre situation unique pour vous offrir des solutions adaptées",
            "Recherche d’accords amiables : Nous privilégions des solutions pacifiées et rapides lorsque cela est possible, afin de préserver vos relations et de limiter les conflits.",
            "Défense ferme : En cas de litige, nous vous représentons avec rigueur devant les tribunaux pour protéger vos droits.",
            "Expertise stratégique : Nous élaborons des stratégies juridiques efficaces, adaptées à vos besoins et objectifs.",
            "Accompagnement complet : De la première consultation jusqu’à la résolution du dossier, nous vous guidons à chaque étape du processus."
        ],
        icon: require("./images/banners/Droit des baux.png"),
    },
    {
        id: "droit-du-travail",
        title: "Travail",
        bannerText: "Protéger vos droits sociaux et du travail avec expertise et rigueur",
        smallDescription: "Salarié ou employeur, nous vous conseillons et vous représentons dans toutes vos démarches liées au contrat de travail, licenciement ou litiges professionnels.",
        description: "Le droit du travail encadre les relations entre employeurs et salariés, garantissant le respect des droits et des obligations de chacun. Que vous soyez confronté à un licenciement, une discrimination, ou à un conflit dans votre entreprise, notre cabinet est à vos côtés pour vous conseiller et vous représenter efficacement. Egalement dans vos démarches vis-à-vis des institutions ou organismes sociaux.\n" +
            "\n" +
            "Au cabinet Ranieri, à Charleroi, nous analysons avec soin chaque situation pour proposer des solutions adaptées et pragmatiques. Qu’il s’agisse de résoudre un litige, de négocier un contrat, ou de défendre vos intérêts devant les juridictions compétentes, nous mettons à profit notre expertise et notre engagement pour vous accompagner à chaque étape.\n",
        useCases: [
            { icon: require("./images/icons/Licenciement.png"), text: "Licenciement abusif ou conflit lié au contrat de travail : Assistance et défense pour contester une rupture injustifiée ou défendre les droits des employeurs." },
            { icon: require("./images/icons/Contrats.png"), text: "Rédaction et négociation de contrats : Conseils pour établir des contrats de travail conformes aux réglementations en vigueur." },
            { icon: require("./images/icons/Harcèlement.png"), text: "Harcèlement et discrimination : Accompagnement pour dénoncer ou gérer des situations d’inégalité ou de pression au travail." },
            { icon: require("./images/icons/Droit social.png"), text: "Recours contre les décisions administratives : ONEM, mutuelles, INAMI, CPAS et caisse d'allocations familiales." },
            { icon: require("./images/icons/Accident du travail.png"), text: "Accidents de travail : Assistance pour faire valoir vos droits en cas d’accident ou de maladie professionnelle." },
        ],
        whyUs: [
            "Écoute personnalisée : Nous prenons le temps de comprendre votre situation unique pour vous offrir des solutions adaptées",
            "Recherche d’accords amiables : Nous privilégions des solutions pacifiées et rapides lorsque cela est possible, afin de préserver vos relations et de limiter les conflits.",
            "Défense ferme : En cas de litige, nous vous représentons avec rigueur devant les tribunaux pour protéger vos droits.",
            "Expertise stratégique : Nous élaborons des stratégies juridiques efficaces, adaptées à vos besoins et objectifs.",
            "Accompagnement complet : De la première consultation jusqu’à la résolution du dossier, nous vous guidons à chaque étape du processus."
        ],
        icon: require("./images/banners/Droit du travail.png"),
    },
    {
        id: "droit-penal-cassation",
        title: "Pénal & Cassation",
        bannerText: "Une expertise reconnue en droit pénal et cassation pour vous accompagner à chaque étape de vos démarches judiciaires",
        smallDescription: "Accusé ou victime, bénéficiez d'une défense rigoureuse et stratégique en matière pénale, y compris dans les procédures de cassation grâce à une expertise approfondie.",
        description: "Le droit pénal protège les droits fondamentaux des individus tout en assurant le respect de la justice. Que vous soyez accusé, victime, ou confronté à une décision injuste nécessitant une révision en cassation, notre cabinet vous accompagne avec rigueur et professionnalisme.\n" +
            "\n" +
            "Au cabinet Ranieri, à Charleroi, nous comprenons l’importance des enjeux liés à vos affaires pénales. Avec une expertise approfondie en procédure pénale et cassation, nous analysons chaque détail de votre dossier pour construire une défense stratégique et adaptée. Notre mission est de vous représenter efficacement devant toutes les juridictions, tout en vous offrant un accompagnement humain et rassurant.",
        useCases: [
            { icon: require("./images/icons/Infractions.png"), text: "Infractions pénales : Défense de vos intérêts en cas de délits devant les tribunaux correctionnels." },
            { icon: require("./images/icons/Assistance des parties préjudiciées.png"), text: "Assistance des parties préjudiciées : Dépôt de plainte, action pénale, suivi du dossier, calcul et réclamation du dommage." },
            { icon: require("./images/icons/Protection des victimes.png"), text: "Protection des victimes : Accompagnement des victimes d’infractions pour garantir une juste réparation." },
            { icon: require("./images/icons/Cassation.png"), text: "Cassation : Analyse approfondie et recours devant la Cour de cassation pour contester une décision judiciaire définitive." },
        ],
        whyUs: [
            "Écoute personnalisée : Nous prenons le temps de comprendre votre situation unique pour vous offrir des solutions adaptées",
            "Recherche d’accords amiables : Nous privilégions des solutions pacifiées et rapides lorsque cela est possible, afin de préserver vos relations et de limiter les conflits.",
            "Défense ferme : En cas de litige, nous vous représentons avec rigueur devant les tribunaux pour protéger vos droits.",
            "Expertise stratégique : Nous élaborons des stratégies juridiques efficaces, adaptées à vos besoins et objectifs.",
            "Accompagnement complet : De la première consultation jusqu’à la résolution du dossier, nous vous guidons à chaque étape du processus."
        ],
        icon: require("./images/banners/Droit pénal et cassation.png"),
    },
];

export const teamMembers = [
    {
        name: "Santa Ranieri",
        description: "Depuis 1991, <strong>Maître Ranieri</strong>, avocate au Barreau de Charleroi, met son expertise et son approche humaine au service de ses clients. Diplômée en droit de l’Université Catholique de Louvain (UCL), elle a consacré plus de 30 ans à accompagner et défendre des particuliers et des entreprises dans leurs affaires juridiques. Son expérience variée, combinée à une rigueur juridique et un pragmatisme exemplaire, lui permet d’apporter des solutions adaptées aux besoins spécifiques de chaque client.\n" +
            "\n" +
            "Animée par la volonté de défendre vos intérêts avec excellence, Santa Ranieri a enrichi ses compétences grâce à des expériences significatives, notamment en tant que Juge de paix Suppléant à Charleroi. Engagée dans des méthodes modernes comme le droit collaboratif et formée en gestion financière, elle dispose des outils nécessaires pour résoudre les litiges les plus complexes, que ce soit dans un cadre amiable ou judiciaire.",
        image: require("./images/avocate charleroi santa ranieri.jpg"),
        phone: "071 32 81 18",
        expertise: [
            "<strong>Droit pénal et cassation</strong> : Défense devant toutes les juridictions, y compris pour les affaires en cassation.",
            "<strong>Droit de la famille</strong> : Accompagnement pour divorces, successions, et affaires de garde d’enfants.",
            "<strong>Droit collaboratif</strong> : Résolution amiable et apaisée des conflits grâce à des méthodes alternatives.",
            "<strong>Litiges financiers</strong> : Analyse et gestion de dossiers complexes liés aux finances et à la comptabilité.",
            "<strong> Droit bancaire et des biens</strong> : Litiges locatifs, saisies immobilières, et autres affaires patrimoniales."
        ],
    },
    {
        name: "Alessandra Buffa",
        description: "Inscrite au Barreau de Charleroi depuis 2014, Maître Buffa est diplômée en droit de l’Université de Namur et a obtenu son master en droit européen à l’Université Catholique de Louvain-La-Neuve, avec une orientation en droit de la responsabilité civile, réparation et évaluation du dommage. Forte de ces solides bases académiques et d’une expérience pratique diversifiée, elle met son savoir-faire au service de la défense des intérêts de ses clients.\n" +
            "\n" +
            "Engagée et polyvalente, <strong>Maître Buffa</strong> intervient principalement dans des affaires liées au droit familial, au droit de la jeunesse, ou encore au droit de la responsabilité civile. Elle assiste ses clients devant toutes les juridictions francophones de Belgique, généralement en personne, garantissant ainsi une prise en charge directe et de qualité. De plus, elle est régulièrement désignée en tant qu’administratrice des biens et/ou de la personne. Sensible aux enjeux d’accessibilité, Maître Buffa travaille également sous le bénéfice de l’aide juridique (\"Pro Deo\").",
        image: require("./images/avocate charleroi alessandra buffa.jpg"),
        phone: "071 32 81 18",
        expertise: [
            "<strong>Droit familial</strong> : Litiges relatifs aux divorces, gardes d’enfants, pensions alimentaires.",
            "<strong>Droit de la jeunesse </strong> : Assistance dans les procédures impliquant des mineurs.",
            "<strong>Droit de la responsabilité civile</strong> : Gestion des dossiers liés à la réparation des dommages.",
            "<strong>Droit de la circulation routière</strong> : Défense en cas d’infractions ou d’accidents de roulage.",
        ],
    },
]