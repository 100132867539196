import '../styles/AboutPage.css';
import Header from "../components/Header";
import HeroBanner from "../components/HeroBanner";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import {teamMembers} from "../assets/data";
import Team from "../components/Team";


function AboutPage() {
    return (
        <div className="App">
            {/* Header */}
            <Header/>

            {/* Gradient line above the banner */}
            <div className="gradient-line"></div>

            {/* Hero section */}
            <HeroBanner
                title="Notre équipe d'Avocates :"
                text="Deux expertes en droit pour vous conseiller et vous représenter dans toutes vos affaires juridiques"
                image={require("../assets/images/banners/cabinet-avocat-charleroi.jpg")}
            />

            {/* Gradient line below the banner */}
            <div className="gradient-line"></div>

            {/* Meet the team */}
            <div className="meet-the-team">
                <h2>Notre équipe</h2>
                {teamMembers.map((member, index) => (
                    <Team
                        key={member.name}
                        name={member.name}
                        description={member.description}
                        image={member.image}
                        phone={member.phone}
                        isImageRight={index % 2 === 0}
                        expertise={member.expertise}
                    />
                ))}
            </div>

            {/* Contact */}
            <Contact/>

            {/* Gradient line above the footer */}
            <div className="gradient-line"></div>

            {/* Footer */}
            <div className="wrapper">
                <Footer/>
            </div>
        </div>
    );
}

export default AboutPage;
