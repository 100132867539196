import React from "react";
import DOMPurify from "dompurify";
import "../styles/components/Team.css";

const Team = ({ name, description, image, phone, isImageRight, expertise }) => {
    // Split description text by `\n` and create an array of paragraphs
    const paragraphs = description.split("\n");

    // Sanitize HTML content
    const createSanitizedHTML = (html) => {
        return { __html: DOMPurify.sanitize(html) };
    };

    return (
        <div className={`team-member ${isImageRight ? "image-right" : "image-left"}`}>
            <div className="content">
                <h3>{name}</h3>
                <div className="description">
                    {paragraphs.map((paragraph, index) => (
                        <p key={index} dangerouslySetInnerHTML={createSanitizedHTML(paragraph)} />
                    ))}
                </div>
                {expertise && (
                    <div className="expertise">
                        <h4>Domaines d'expertise</h4>
                        <ul>
                            {expertise.map((item, index) => (
                                <li key={index} dangerouslySetInnerHTML={createSanitizedHTML(item)} />
                            ))}
                        </ul>
                    </div>
                )}
                <button className="cta-button" onClick={() => window.location.href = `tel:${phone}`}>
                    {phone}
                </button>
            </div>
            <div className="image">
                <img src={image} alt={`Portrait de Maitre ${name}`} />
            </div>
        </div>
    );
};

export default Team;
