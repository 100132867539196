import '../styles/TermsAndConditionPage.css';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Helmet} from "react-helmet";

function TermsAndConditionPage() {
    return (
        <div className="App">
            {/* Meta Tags for SEO */}
            <Helmet>
                <title>Politique de confidentialité</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            {/* Header */}
            <Header/>

            {/* Gradient line above the banner */}
            <div className="gradient-line"></div>

            {/* Terms and conditions */}
            <div className="terms-and-condition wrapper">
                <h2 className>Politique de Confidentialité</h2>
                <p>Votre confidentialité est importante pour nous. Cette politique de confidentialité explique comment
                    le
                    Cabinet Ranieri gère les informations lorsque vous visitez notre site web. Nous ne collectons
                    directement aucune donnée personnelle via notre site, mais nous utilisons des outils tiers qui
                    peuvent
                    collecter certaines informations de manière indépendante.</p>

                <div className="terms-section">
                    <h3>1. Collecte des informations personnelles</h3>
                    <p>Nous ne collectons directement aucune donnée personnelle via notre site web. Cependant, nous
                        utilisons
                        des services tiers, tels que Google Maps et Google Search Engine, intégrés sous forme d’iframe. Ces outils peuvent
                        collecter des
                        données automatiquement, notamment :</p>
                    <ul>
                        <li>Adresse IP</li>
                        <li>Données de navigation</li>
                        <li>Informations techniques relatives à votre appareil</li>
                    </ul>
                    <p>Nous vous invitons à consulter les politiques de confidentialité de ces services pour comprendre comment vos données peuvent être utilisées :</p>
                    <ul>
                        <li><a href="https://policies.google.com/privacy">Politique de confidentialité de Google</a></li>
                    </ul>
                </div>

                <div className="terms-section">
                    <h3>2. Utilisation des informations personnelles</h3>
                    <p>Nous n’utilisons aucune information personnelle collectée directement via notre site. Toute
                        donnée
                        collectée par des outils tiers est gérée par leurs propres politiques de confidentialité.</p>
                </div>

                <div className="terms-section">
                    <h3>3. Partage des informations personnelles</h3>
                    <p>Nous ne partageons aucune donnée personnelle directement collectée par notre site, car aucune
                        collecte
                        directe n’est effectuée. Les données collectées par des tiers (comme Google) sont gérées
                        indépendamment
                        par ces derniers.</p>
                </div>

                <div className="terms-section">
                    <h3>4. Conservation des données</h3>
                    <p>Puisque nous ne collectons pas de données personnelles, nous ne conservons aucune information.
                        Les
                        services tiers comme Google gèrent la conservation de leurs données selon leurs propres
                        politiques.</p>
                </div>

                <div className="terms-section">
                    <h3>5. Sécurité des données</h3>
                    <p>Nous veillons à intégrer des outils tiers de manière sécurisée. Toutefois, la sécurité des
                        données
                        collectées par ces outils dépend des pratiques des tiers, comme Google.</p>
                </div>

                <div className="terms-section">
                    <h3>6. Vos droits</h3>
                    <p>Comme aucune donnée n’est directement collectée par notre site, vos droits relatifs à vos données
                        personnelles s’appliquent principalement aux services tiers que nous utilisons. Nous vous recommandons de consulter leurs politiques de confidentialité pour exercer vos droits :</p>
                    <ul>
                        <li><a href="https://support.google.com/policies/answer/9581826">Exercer vos droits auprès de Google</a></li>
                    </ul>
                </div>

                <div className="terms-section">
                    <h3>7. Utilisation des cookies</h3>
                    <p>Notre site n’utilise pas directement de cookies, mais les outils tiers intégrés, comme Google
                        Maps,
                        peuvent utiliser leurs propres cookies. Vous pouvez gérer vos préférences en matière de cookies
                        via les
                        paramètres de votre navigateur.</p>
                    <p>Toutefois, Google Search Engine peut collecter certaines informations sans stocker de cookies sur votre appareil. Nous vous recommandons de consulter la politique de confidentialité de Google pour plus de détails.</p>
                </div>

                <div className="terms-section">
                    <h3>8. Utilisation des images et crédits</h3>
                    <p>Certaines images utilisées sur ce site sont fournies par des plateformes libres de droits et peuvent nécessiter une attribution conformément à leurs conditions d’utilisation. Voici les attributions associées :</p>
                    <ul className="resource-list">
                        <li><a href="https://www.flaticon.com/free-icon/cancel_594598">Icône de croix</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/speed-limit_2584782">Icône d'excès de vitesse</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/fender-bender_2125190">Icône d'accident de la route</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/driving-license_7831414">Icône de permis de conduire</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/insurance_2125215">Icône d'assurance auto</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/house_2125210">Icône d'assurance maison</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/police_7923349">Icône de policière</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/divorce_9893947">Icône de divorce</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/family-member_3088419">Icône de garde d'enfants</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/give-money_2510743">Icône de pension alimentaire</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/divorce_1612944">Icône de séparation des biens</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/beneficiary_9875126">Icône de succession</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/salary_3135706">Icône d'indemnisation</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/dispute_5110050">Icône de litiges</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/papyrus_15564322">Icône de contrats</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/agreement_3188168">Icône de signature de contrats</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/policy_17773157">Icône d'analyse de contrats</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/meeting_3945911">Icône de négociations</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/procurement_4866817">Icône de ventes</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/money_3146459">Icône de crédit</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/house_619153">Icône de saisies immobilières</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/debt_9882182">Icône de surendettement</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/mortgage_3146425">Icône de loyer impayé</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/deal_1441353">Icône de résiliation de bail</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/home_18277047">Icône de révision des loyers</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/roof_15551313">Icône des obligations locatives</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/neighborhood_18274068">Icône de litiges de voisinage</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/no-job_9306126">Icône de licenciement</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/discrimination_9826036">Icône d'harcèlement et discrimination</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/social-justice_10554183">Icône de justice sociale</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/injury_7374125">Icône d'accident du travail</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/financial-law_13375083">Icône d'indemnisations</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/health-law_13375196">Icône d'assistance juridique</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/judge_13375160">Icône de juge de la Cours de Cassation</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/clipboard_1962592">Bannière du droit des assurances</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/agreement_8898421">Bannière du droit des contrats</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/bank_2830155">Bannière du droit bancaire</a></li>
                        <li><a href="http://flaticon.com/free-icon/deal_1441242">Bannière du droit de bail</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/workers_10293158">Bannière du droit du travail</a></li>
                        <li><a href="https://www.flaticon.com/free-icon/balance_924954">Bannière du droit pénal et cassation</a></li>
                    </ul>
                    <p>Pour toute question concernant ces ressources, veuillez nous contacter.</p>
                </div>

                <div className="terms-section">
                    <h3>9. Mentions légales (Article 18 de la loi du 26/03/2010 - Livre III – 74 du Code de droit économique)</h3>
                    <p>Conformément à l’Article 18 de la loi du 26/03/2010 - Livre III – 74 du Code de droit économique, nous mettons à disposition les informations suivantes :</p>
                    <p><strong>Nom ou dénomination sociale :</strong> RANIERI SANTA</p>
                    <p><strong>Adresse du cabinet d’avocat :</strong> Bd de Fontaine , 21/042 – 6000 CHARLEROI</p>
                    <p><strong>Numéro d’entreprise (BCE) :</strong> BCE 0892.827.689</p>
                    <p><strong>Adresse électronique :</strong> ranieri.avocate@skynet.be</p>
                    <p><strong>Organisation professionnelle :</strong>  Barreau de CHARLEROI</p>
                    <div className="italic">
                        <p>Palais de Justice</p>
                        <p>Boulevard Général Michel, 2</p>
                        <p>6000 CHARLEROI</p>
                    </div>
                    <p><strong>Titre professionnel :</strong>  Avocat</p>
                    <p><strong>Pays ayant octroyé ce titre professionnel :</strong>  Belgique</p>
                    <p><strong>Prix du service déterminé au préalable :</strong>  Voir convention d'honoraires</p>
                    <p><strong>Caractéristique de la prestation de service et du produit :</strong></p>
                    <ul>
                        <li>Activités d’avocat, code NACEBEL 2008 : 69101</li>
                        <li>Description de la mission de l’avocat ex : Défense en justice, consultation en matière civile, commerciale, sociale, fiscale, administrative, médiation, etc…</li>
                    </ul>
                    <p>Conditions générales applicables, le cas échéant avec renvoi à celles-ci</p>
                    <p><strong>Assurances :</strong>  RC professionnelle : compagnie d’assurance ETHIAS, rue des Croisiers, 24 à 4000 LIEGE (téléphone : 04/220.31.11)</p>
                </div>

                <div className="terms-section">
                    <h3>10. Modifications de la politique de confidentialité</h3>
                    <p>Cette politique peut être mise à jour à tout moment. Toute modification sera publiée sur cette
                        page.</p>
                </div>

                <div className="terms-section">
                    <h3>11. Contact</h3>
                    <p>Pour toute question concernant cette politique de confidentialité ou les outils tiers utilisés
                        sur notre site, contactez-nous à :</p>
                    <div className="italic">
                        <p>Santa Ranieri</p>
                        <p>Boulevard de Fontaine 21/042,</p>
                        <p>6000 Charleroi</p>
                        <p>Téléphone: +32 71 32 81 18</p>
                        <p>Email: ranieri.avocate@skynet.be</p>
                    </div>
                </div>

                <p>Cette politique reflète notre engagement à respecter votre confidentialité et à fournir des
                    informations transparentes concernant l’utilisation des outils tiers  et des ressources visuelles sur notre site.</p>
            </div>

            {/* Gradient line above the footer */}
            <div className="gradient-line"></div>

            {/* Footer */}
            <div className="wrapper">
                <Footer/>
            </div>
        </div>
    );
}

export default TermsAndConditionPage;